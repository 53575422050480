import { type ImageFilter } from '~/components/ui/image'

type ImagePathFilterProps = {
	path: string
	filter: ImageFilter
}

function imagePathFilter({ path, filter }: ImagePathFilterProps) {
	return path.replace('/image/', `/cache/resolve/${filter}/`)
}

export { imagePathFilter, type ImagePathFilterProps }
