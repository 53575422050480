import { Card, Container, Flex, Heading, Text } from '@radix-ui/themes'
import { useRouteError, isRouteErrorResponse } from '@remix-run/react'
import * as React from 'react'
import { TbFaceIdError } from 'react-icons/tb'
import { Icon } from '~/components/ui/icon.tsx'

/* -------------------------------------------------------------------------------------------------
 * GenericErrorBoundary
 * -----------------------------------------------------------------------------------------------*/

const GenericErrorBoundary: React.FC = () => {
	const error = useRouteError()

	if (isRouteErrorResponse(error)) {
		return (
			<Container px="5" my="9">
				<Flex justify="center">
					<Card>
						<Flex direction="column" justify="center" align="center" gapY="5">
							<Flex direction="column" justify="center" align="center" gapY="2">
								<Heading color="gray">{error.data.title}</Heading>
								<Text as="p" color="gray">
									{error.data.status}: {error.status}
								</Text>
							</Flex>
							<Heading as="h4">{error.data.message}</Heading>
							<Text as="p" weight="bold">
								{error.data.description}
							</Text>
							<Icon color="green">
								<TbFaceIdError size="3.5em" />
							</Icon>
						</Flex>
					</Card>
				</Flex>
			</Container>
		)
	}

	return (
		<Container px="5" my="9">
			<Flex justify="center">
				<Card>
					<Flex direction="column" justify="center" align="center" gapY="5">
						<Flex direction="column" align="center" justify="center" gapY="2">
							<Heading color="gray">Uh oh ...</Heading>
							<Text as="p">Something went wrong</Text>
						</Flex>
						<Heading as="h4">
							{error instanceof Error ? error.message : 'Unknown error'}
						</Heading>
						<Icon color="green">
							<TbFaceIdError size="3.5em" />
						</Icon>
					</Flex>
				</Card>
			</Flex>
		</Container>
	)
}
GenericErrorBoundary.displayName = 'GenericErrorBoundary'

/* -----------------------------------------------------------------------------------------------*/

export { GenericErrorBoundary }
