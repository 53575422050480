import { type MetaArgs } from '@remix-run/node'
import isEqual from 'lodash.isequal'
import uniqWith from 'lodash.uniqwith'

import { type Locale } from '~/models/locale'
import { defaultMetaI18next } from '~/utils/client/default-meta-i18next'

type MetaDefaultProps = MetaArgs & {
	ROUTE_LOADER: any
	I18NEXT: any
}

function metaDefault({
	ROUTE_LOADER,
	I18NEXT,
	data,
	matches,
}: MetaDefaultProps) {
	const parentMeta = matches.reduce((a, b) =>
		a.meta.length > b.meta.length ? a : b,
	).meta
	const title = parentMeta.find((meta) => 'title' in meta)
	const description = parentMeta.find(
		(meta) => 'name' in meta && meta.name === 'description',
	)

	const rootData = matches.find((match) => match.id === ROUTE_LOADER.root)!
		.data as { locale: Locale }

	// @ts-ignore
	return uniqWith(
		title?.title.includes('Error')
			? [...parentMeta]
			: data
				? [
						...parentMeta.filter(
							(meta) =>
								!Object.entries(meta).find(
									([key, value]) =>
										key === 'title' ||
										(key === 'name' && value === 'description'),
								),
						),
						// @ts-ignore
						{
							title: data?.meta?.title
								? `${data.meta.title} | ${title?.title}`
								: title?.title,
						},
						{
							name: 'description',
							// @ts-ignore
							content: data?.meta?.description
								? `${data.meta.description} | ${description?.content}`
								: description?.content,
						},
						// I18next
						...defaultMetaI18next<typeof I18NEXT>({
							I18NEXT,
							locale: rootData.locale,
						}),
					]
				: [
						...parentMeta.filter(
							(meta) =>
								!Object.entries(meta).find(
									([key, value]) =>
										key === 'title' ||
										(key === 'name' && value === 'description'),
								),
						),
						// @ts-ignore
						{ title: `Error | ${title?.title}` },
						{
							name: 'description',
							// @ts-ignore
							content: `Error | ${description?.content}`,
						},
					],
		(a, b) => isEqual(a, b),
	)
}

export { metaDefault, type MetaDefaultProps }
