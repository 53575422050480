import { type TextProps, Text } from '@radix-ui/themes'
import * as React from 'react'

type IconProps = TextProps

const Icon: React.FC<IconProps> = ({ children, ...rest }) => {
	return <Text {...rest}>{children}</Text>
}

export { type IconProps, Icon }
