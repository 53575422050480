import { type Locale } from '~/models/locale'

type DefaultMetaI18nextProps<T> = {
	I18NEXT: T
	locale: Locale
}

function defaultMetaI18next<T>({
	I18NEXT,
	locale,
}: DefaultMetaI18nextProps<T>) {
	return Object.values(I18NEXT as string[]).map((value) => ({
		tagname: 'link',
		rel: 'preload',
		as: 'fetch',
		href: `/pwa/locales/${locale}/${value}.json`,
		crossOrigin: 'anonymous',
	}))
}

export { defaultMetaI18next }
