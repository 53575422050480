import * as React from 'react'
import { type FitEnum } from 'sharp'

import { imagePathFilter } from '~/utils/image-path-filter.ts'

/* -------------------------------------------------------------------------------------------------
 * Image
 * -----------------------------------------------------------------------------------------------*/

type BaseImageProps = {
	alt: string
	crossorigin?: 'anonymous' | ' use-credentials'
	height?: number
	longdesc?: string
	ismap?: string
	loading?: 'eager' | 'lazy'
	referrerpolicy?:
		| 'no-referrer'
		| 'no-referrer-when-downgrade'
		| 'origin'
		| 'origin-when-cross-origin'
		| 'unsafe-url'
	sizes?: string
	src: string
	srcset?: string
	usemap?: string
	width?: number
}

type Picture = {
	sources?: {
		srcset: string[]
		media: string
		width: string | number
		height: string | number
	}[]
	img: {
		src: string
		alt: string
		srcset?: string[]
		width: string | number
		height: string | number
	}
}

type ImageFilter = 'grid_filter' | 'slider_filter'

type ImageProps = React.ComponentPropsWithoutRef<'img'> &
	BaseImageProps & {
		src?: string
		width?: number
		height?: number
		fit?: keyof FitEnum // contain is default
		alt: string
		api?: boolean
		className?: string
		filter?: ImageFilter
		picture?: Picture
	}

enum FILTERS {
	gridFilter = 'grid_filter',
	sliderFilter = 'slider_filter',
}

enum ACTIONS {
	route = '/action/resize',
	placeholder = '/pwa/product-img-placeholder.svg',
}

const Image = React.forwardRef<HTMLImageElement, ImageProps>(
	(props, forwardedRef) => {
		const {
			children,
			fit,
			src,
			alt,
			filter,
			picture,
			api = true,
			width = picture?.img.width,
			height = picture?.img.height,
			className,
			...rest
		} = props

		/*
      const query = new URLSearchParams()
      if (width) {
        query.set('w', width.toString())
      }
      if (height) {
        query.set('h', height.toString())
      }
      if (fit) {
        query.set('fit', fit)
      }
     */

		const path = api
			? filter
				? imagePathFilter({
						path: src?.replace('http://php', 'https://localhost'),
						filter,
					})
				: src?.replace('http://php', 'https://localhost')
			: picture
				? `${ACTIONS.route}${picture.img.src}`
				: src ?? ACTIONS.placeholder

		return (
			<picture {...rest}>
				{picture?.sources?.map(
					({ srcset, media, width, height }, i: number) => (
						<source
							key={i}
							srcSet={srcset?.map((src) => `${ACTIONS.route}${src}`).join()}
							media={media}
							width={width}
							height={height}
						/>
					),
				)}
				<img
					ref={forwardedRef}
					alt={alt}
					src={path}
					className={className}
					{...{
						width,
						height,
						...(picture?.img.srcset && {
							srcSet: picture.img.srcset
								.map((src) => `${ACTIONS.route}${src}`)
								.join(),
						}),
					}}
				/>
			</picture>
		)
	},
)
Image.displayName = 'Image'

/* -----------------------------------------------------------------------------------------------*/

export {
	Image,
	FILTERS as IMAGE_FILTERS,
	ACTIONS as IMAGE_ACTIONS,
	type ImageFilter,
	type Picture,
}
